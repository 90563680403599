const colorArr = [
  0x00ffff,
  0xd900d9,
  0x80ff00,
  0x8000ff,
  0x00ff80,
  0x5cffad,
  0xff5cad,
  0xad5cff,
  0xffad5c,
  0x5cfffe,
  0x4acc8a,
  0xcc4a8a,
  0x2f5882,
  0x582f82,
  0x2f3082,
  0x30822f,
  0x2f8281,
];
export { colorArr };
